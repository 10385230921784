html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto Condensed', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

body div[id=root] {
    height: 100%;
}

button, input {
    font-family: inherit;
}  

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: #2f4f4f;
    border: 2px dashed #ffffff;
    border-radius: 5px;
    cursor: pointer;
    margin: 15px;
}

.custom-file-upload {
    display: inline-block;
    text-align: center;
    color: white;
    line-height: 1.5;
}

.custom-file-upload .button-label {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
}

.custom-file-upload .button-desc {
    color: #808080;
}

.custom-file-upload .leading-by-spinner {
    color: white;
    margin-left: 10px;
}

input[type="file"] {
    display: none;
}

input[type="file"]:focus+.custom-file-upload,
.upload-button:hover {
    border-color: cyan;
}

input[type="file"]:focus+.custom-file-upload {
    outline: -webkit-focus-ring-color auto 5px;
}

.file-item {
    position: relative;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 10px 25px;
    cursor: pointer;
}

.file-item:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.file-item i {
    margin-right: 18px;
}

.file-name {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-item:hover::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0px;
    bottom: 0px;
    width: 50px;
}

.file-item:hover::after {
    background: linear-gradient(to right, rgba(46, 46, 49, 0.1), rgba(46, 46, 49, 1));
}

.file-item.selected::after {
    background: linear-gradient(to right, rgba(59, 61, 63, 0.1), rgba(59, 61, 63, 1));
}

.file-item.selected {
    background-color: rgba(255, 255, 255, 0.1);
}

.delete-icon {
    z-index: 99;
    position: absolute;
    right: 15px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    display: none;
}

.file-item:hover .delete-icon {
    display: inline;
    margin: 0;
}

.doc-reader {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-width: 0;
}

.modal-overlay {
    z-index: 9990;
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 36px;
    min-width: 80px;
    font-size: 1em;
    color: rgba(217, 217, 227, 1);
    border: 1px solid rgba(86, 88, 105, 1);
    border-radius: 3px;
    background-color: rgba(52, 53, 65, 1);
    margin: 10px 26px 10px 10px;
    cursor: pointer;
}

.modal-button:hover {
    background-color: rgba(64, 65, 79, 1);
}

.yes-button {
    background-color: #0052cc;
    border-color: #0052cc;
}

.yes-button:hover {
    background-color: rgba(0, 82, 204, 0.8);
    border-color: rgba(0, 82, 204, 0.8);
}

.danger-button {
    background-color: red;
    border-color: red;
}

.danger-button:hover {
    background-color: rgba(255, 0, 0, 0.8);
    border-color: rgba(255, 0, 0, 0.8);
}
